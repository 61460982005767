import React from 'react';
import { translate } from 'instances/i18n';
import Link from 'components/Link';
import PrivacyLink from 'containers/PrivacyLink';

export default () => (
  <nav className="about-menu">
    <Link className="about-menu-link" href="/about">
      {translate('about.about.navigation')}
    </Link>
    <Link className="about-menu-link" href="/about/termsandconditions">
      {translate('about.terms_and_conditions.navigation')}
    </Link>
    <PrivacyLink className="about-menu-link">
      {translate('about.privacy_statement.navigation')}
    </PrivacyLink>
    <Link className="about-menu-link" href="https://blendle.homerun.co/">
      {translate('navigation.links.jobs.label')}
    </Link>
  </nav>
);
