import React from 'react';
import { translate, translateElement } from 'instances/i18n';

const TermsAndConditionsAddendum = () => (
  <div className="l-text pane termsandconditions">
    <h1 className="title">{translate('about.terms_and_conditions.addendum.title')}</h1>
    {translateElement('about.terms_and_conditions.addendum.contents')}
    <p className="end">•••</p>
  </div>
);

export default TermsAndConditionsAddendum;
