import React from 'react';
import PropTypes from 'prop-types';
import { translate, translateElement } from 'instances/i18n';
import { history } from 'byebye';
import { getExceptionForCountry } from 'helpers/countryExceptions';
import Toggle from 'components/buttons/Toggle';

export default class Privacy extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onToggleSharePrivacy: PropTypes.func.isRequired,
    onToggleTracking: PropTypes.func.isRequired,
    onToggleRetargeting: PropTypes.func.isRequired,
    trackingLoading: PropTypes.bool.isRequired,
    shareLoading: PropTypes.bool.isRequired,
    retargetingLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    // If we're viewing this from the settings page, we should update the
    // internal links, so they redirect the /settings as well
    if (/^settings\//.test(history.fragment)) {
      Array.from(document.querySelectorAll('#termsandconditions a')).forEach(link => {
        const aboutPathname = link.href.match(/\/about\/.*/);
        if (aboutPathname) {
          link.setAttribute('href', aboutPathname[0].replace(/\/about\//, '/settings/'));
        }
      });
    }
  }

  _renderProvidersToggle() {
    const { user } = this.props;

    if (user && getExceptionForCountry(user.get('country'), 'showProviderTrackingSetting', false)) {
      return (
        <div className="providers-opt-in opt-switch">
          <Toggle
            checked={!this.props.user.get('publisher_hashed_email_share_opt_out')}
            onToggle={this.props.onToggleSharePrivacy}
            inactive={this.props.shareLoading}
          />
          Ja, Blendle mag mijn e-mailadres in gehashte vorm delen met uitgevers om ze te helpen te
          begrijpen wie van hun lezers gebruikmaken van Blendle.
        </div>
      );
    }

    return null;
  }

  _renderTrackingToggle() {
    const { user } = this.props;

    if (user && !user.hasActivePremiumSubscription()) {
      return (
        <div className="analytics-opt-out opt-switch">
          <Toggle
            checked={!this.props.user.get('mixpanel_opt_out')}
            onToggle={this.props.onToggleTracking}
            inactive={this.props.trackingLoading}
          />
          {translate('app.text.agree_to_analytics')}
        </div>
      );
    }

    return null;
  }

  _renderRetargetingToggle() {
    const { user } = this.props;

    if (user && getExceptionForCountry(user.get('country'), 'showRetargetingSetting', false)) {
      return (
        <div className="analytics-opt-out opt-switch">
          <Toggle
            checked={!this.props.user.get('ad_retargeting_opt_out')}
            onToggle={this.props.onToggleRetargeting}
            inactive={this.props.retargetingLoading}
          />
          Ja, Blendle mag mijn gegevens in gehashte vorm gebruiken om me aanbevelingen te doen op
          basis van mijn interesses, bijvoorbeeld artikelen op Facebook.
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="pane l-text privacystatement">
        <h1 className="title">{translate('about.privacy_statement.title')}</h1>
        {translateElement('about.privacy_statement.contents')}
        <p className="end">•••</p>
        {this._renderTrackingToggle()}
        {this._renderProvidersToggle()}
        {this._renderRetargetingToggle()}
      </div>
    );
  }
}
