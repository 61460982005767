import React from 'react';
import { translate, translateElement } from 'instances/i18n';
import getSubscriptionOverviewPageUri from 'helpers/paymentPageUrl/getSubscriptionOverviewPageUri';

const AnnouncingUpdatedTermsAndConditions = () => (
  <div className="l-text pane termsandconditions">
    <h1 className="title">{translate('about.announcing_updated_terms_and_conditions.title')}</h1>
    {translateElement('about.announcing_updated_terms_and_conditions.contents', [
      getSubscriptionOverviewPageUri(),
    ])}
    <p className="end">•••</p>
  </div>
);

export default AnnouncingUpdatedTermsAndConditions;
